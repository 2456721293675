import moment from 'moment-timezone';

export const defaultTimeZone = 'Asia/Kolkata';

import { floor, get, padStart, toSafeInteger, round, split, trim, isEmpty } from 'lodash';
import { show24HoursFormatEverywhereForAccount, preferredTimeStampFormat } from './account_utils';
import { queryString } from './string_utils';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_TIME = 'DD/MM/YYYY h:mm A';
export const DATE_FORMAT_TIME_24 = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_TIME_24_REPORTS = 'DD/MM/YY HH:mm';
export const DATE_FORMAT_TIMESTAMP = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_TIMESTAMP_ONLY_TIME = 'HH:mm:ss';
export const DATE_FORMAT_TIMESTAMP_T = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_TIMESTAMP_DAY = 'YYYY-MM-DD';
export const DATE_FORMAT_TIME_REPORT = 'DD-MMM-YYYY-hh-mm-A';
export const DATE_FORMAT_HUMANIZE = 'DD MMM YY, hh:mm A';
export const DATE_FORMAT_HUMANIZE_SHORT = 'DD/M H:mm';
export const DATE_FORMAT_WEEKDAY = 'dddd';
export const DATE_FORMAT_REPORT = 'DD/MM/YY HH:mm';
export const DATE_FORMAT_HUMANIZE_REPORT = 'DD MMM YY hh:mm A';
export const DATE_FORMAT_HUMANIZE_24 = 'DD/MM/YY HH:mm';
export const DATE_FORMAT_HUMANIZE_24_DAY = 'DD/MM/YY';
export const DATE_FORMAT_HUMANIZE_24_TIME = 'HH:mm';
export const DATE_FORMAT_HUMANIZE_24_TIME_WITH_SECONDS = 'HH:mm:ss';
export const DATE_FORMAT_HUMANIZE_MONTH = 'DD MMM YY';

export const DATE_FORMAT_HUMANIZE_WITHOUT_YEAR = 'DD MMM, hh:mm A';

export const DATE_FORMAT_HUMANIZE_SECONDS = 'DD MMM YY, hh:mm:ss A';
export const DATE_FORMAT_HUMANIZE_SECONDS_24 = 'DD/MM/YY, HH:mm:ss';
export const DATE_FORMAT_HUMANIZE_DAY = 'DD MMM YY';
export const DATE_FORMAT_HUMANIZE_DAY_MONTH = 'DD/MM ';
export const DATE_FORMAT_HUMANIZE_TIME = 'hh:mm A';
export const DATE_FORMAT_TIME_MINUTE_ONLY = 'H:mm';
export const DATE_FORMAT_TIME_ONLY = 'H:mm:ss';
export const DATE_FORMAT_HUMANIZE_TIME_SECOND = 'hh:mm:ss A';
export const DATE_FORMAT_HUMANIZE_MONTH_YEAR = 'MMMM, YYYY';
export const DATE_FORMAT_HUMANIZE_MMM_YEAR = 'MMM, YYYY';
export const DATE_FORMAT_DAY_OF_MONTH = 'D';
export const DATE_FORMAT_LOCAL_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_UNIX_TIMESTAMP = 'x';
export const DATE_FORMAT_ONLY_TIME = 'HH:mm';

export const DEFAULT_START_DATE_DAYS = 0;
export const DEFAULT_START_DATE_DAYS_HISTORY_PAGE = 30;
export const DEFAULT_OTHER_START_DATE_DAYS = 6;
export const DEFAULT_DUTY_START_DATE_DAYS = 29;
export const DEFAULT_JOB_START_DATE_DAYS = 1;
export const DEFAULT_VENDOR_START_DATE_DAYS = 1;
export const DEFAULT_ISSUE_START_DATE_DAYS = 6;
export const DEFAULT_JOB_START_MONTH = 6;
export const DEFAULT_15_DAYS = 15;

export const MINUTES_1 = 1 * 60 * 1000;
export const HOURS_1 = 1 * 3600 * 1000;
export const HOURS_6 = 6 * 3600 * 1000;
export const HOURS_12 = 12 * 3600 * 1000;
export const HOURS_24 = 24 * 3600 * 1000;
export const SECOND_10 = 10 * 1000;

export const ALL_DAYS_TYPE = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const WEEKDAY_TYPES = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export function getHumanizeTime(
    timeStamp,
    showSeconds = false,
    is24HoursFormat = false,
    usePreferredTimeStamp = false
) {
    const preferredDateFormat = usePreferredTimeStamp ? preferredTimeStampFormat() : null;
    let dateFormat = showSeconds ? DATE_FORMAT_HUMANIZE_SECONDS : DATE_FORMAT_HUMANIZE;
    if (is24HoursFormat || show24HoursFormatEverywhereForAccount()) {
        dateFormat = showSeconds ? DATE_FORMAT_HUMANIZE_SECONDS_24 : DATE_FORMAT_HUMANIZE_24;
    }
    return timeStamp && timeStamp._isAMomentObject
        ? timeStamp.format(preferredDateFormat ? preferredDateFormat : dateFormat)
        : getMomentTime(timeStamp).format(preferredDateFormat ? preferredDateFormat : dateFormat);
}

export function getHumanizeTimeOnly(
    timeStamp,
    showSeconds = false,
    is24HoursFormat = false,
    usePreferredTimeFormat = false
) {
    let dateFormat = showSeconds ? DATE_FORMAT_HUMANIZE_TIME_SECOND : DATE_FORMAT_HUMANIZE_TIME;
    if (is24HoursFormat || show24HoursFormatEverywhereForAccount()) {
        dateFormat = showSeconds ? DATE_FORMAT_HUMANIZE_24_TIME_WITH_SECONDS : DATE_FORMAT_HUMANIZE_24_TIME;
    }
    return timeStamp && timeStamp._isAMomentObject
        ? timeStamp.format(dateFormat)
        : getMomentTime(timeStamp).format(dateFormat);
}

export function getHumanizeDayOnly(timeStamp, is24HoursFormat = false, usePreferredDayFormat = false) {
    let dateFormat = DATE_FORMAT_HUMANIZE_DAY;
    if (is24HoursFormat || show24HoursFormatEverywhereForAccount()) {
        dateFormat = DATE_FORMAT_HUMANIZE_24_DAY;
    }
    return timeStamp && timeStamp._isAMomentObject
        ? timeStamp.format(dateFormat)
        : getMomentTime(timeStamp).format(dateFormat);
}

export function getReportTime(timeStamp) {
    let prefFormat = DATE_FORMAT_TIME_24_REPORTS;
    if (preferredTimeStampFormat()) {
        prefFormat = preferredTimeStampFormat();
    }
    return getMomentTime(timeStamp).format(prefFormat);
}

export function getReportTimeDay(timeStamp, showTime = false) {
    return getMomentTime(timeStamp).format(showTime ? DATE_FORMAT_TIMESTAMP : DATE_FORMAT);
}

export function getFormattedTimeStringForAPI(timeStamp, formatType = DATE_FORMAT_TIMESTAMP) {
    return getMomentTime(timeStamp).tz(defaultTimeZone).format(formatType);
}

export function getGMTOffsetDetails() {
    const currDate = getMomentTime();
    const offset = currDate.utcOffset(); //in minutes
    const isBehind = offset < 0;
    const absOffset = Math.abs(offset);
    const hours = toSafeInteger(absOffset / 60);
    let minutes = toSafeInteger(absOffset - hours * 60);
    const sign = isBehind ? '-' : '+';
    const GMTDiff = `GMT${sign}${padStart(`${hours}`, 2, '0')}:${padStart(`${minutes}`, 2, '0')}`;
    return {
        minutes,
        hours,
        sign,
        GMTDiff,
    };
}

/**
 *
 * @param {*} timeStamp
 * @param {*} roundToMin
 * @param {*} format
 * @param {*} moreOptions
 * @returns
 */
export function getMomentTime(timeStamp = null, roundToMin = false, format = '', moreOptions = {}) {
    const { considerDateStringInUserTimezone } = moreOptions || {};
    const query = window.location.search;
    let { timezone = defaultTimeZone } = window.FLEETX_LOGGED_IN_USER || {};

    if (!timezone) {
        timezone = defaultTimeZone;
    }
    if (get(queryString.parse(query), 'timezone')) {
        timezone = get(queryString.parse(query), 'timezone');
    }

    let momentTime = moment();
    if (timeStamp && timeStamp._isAMomentObject) {
        momentTime = timeStamp.clone();
    } else if (timeStamp) {
        if (typeof timeStamp === 'number' || toSafeInteger(timeStamp) > 0) {
            momentTime = moment(toSafeInteger(timeStamp));
        } else if (typeof timeStamp === 'string') {
            // consider given timestamp in Asia/Kolkata timezone(defaultTimeZone)
            const finalTimeZoneForStringTobeConsideredIn =
                considerDateStringInUserTimezone && timezone ? timezone : defaultTimeZone;

            if (!!timeStamp.match(/T/gi)) {
                if (!!timeStamp.match(/Z/gi)) {
                    // UTC String
                    momentTime = moment(timeStamp, format ? format : DATE_FORMAT_TIMESTAMP_T);
                } else {
                    momentTime = moment
                        .tz(
                            timeStamp,
                            format ? format : DATE_FORMAT_TIMESTAMP_T,
                            finalTimeZoneForStringTobeConsideredIn
                        )
                        .tz(timezone);
                }
            } else {
                momentTime = moment
                    .tz(timeStamp, format ? format : DATE_FORMAT_TIMESTAMP, finalTimeZoneForStringTobeConsideredIn)
                    .tz(timezone);
            }
        }
    }

    //note: timezone data is enabled for india(IN) and indonesia(ID) standard timezones. enable it in webpack config for other timezones
    momentTime = momentTime.tz(timezone);

    if (roundToMin) {
        return momentTime.add(30, 'seconds').startOf('minute');
    } else {
        return momentTime;
    }
}

export function getMomentTimeFromObject(timeObject) {
    return moment({
        year: get(timeObject, 'year', 0),
        month: get(timeObject, 'month', 1) - 1,
        day: get(timeObject, 'day', 0),
        hour: get(timeObject, 'hour', 0),
        minute: get(timeObject, 'minute', 0),
        second: get(timeObject, 'seconds', 0),
        millisecond: get(timeObject, 'millisecond', 0),
    });
}

export function getMomentTimeFromArray(timeArray) {
    return moment([
        get(timeArray, '0', 0),
        get(timeArray, '1', 1) - 1,
        get(timeArray, '2', 0),
        get(timeArray, '3', 0),
        get(timeArray, '4', 0),
        get(timeArray, '5', 0),
        get(timeArray, '6', 0),
    ]);
}

export function getTimeDiffHours(duration, hideMinutes, isNumericFormat = false, isPadded = false) {
    const d = moment.duration(duration);
    const h = floor(d.asHours());
    const m = d.minutes();
    const showMinutes = (!hideMinutes || h === 0) && m > 0;
    if (isNumericFormat) {
        if (isPadded) {
            if (m < 0) {
                return `${padStart(h, 2, '0')}:${padStart(Math.abs(m), 2, '0')}`;
            }
            return `${padStart(h, 2, '0')}:${padStart(m, 2, '0')}`;
        } else {
            if (m < 0) {
                return `${h}:${Math.abs(m)}`;
            }
            return `${h}:${m}`;
        }
    } else {
        const returnVal =
            (h > 0 ? `${h} hr${h > 1 ? 's' : ''} ` : '') + (showMinutes ? `${m} min${m > 1 ? 's' : ''}` : '');
        return returnVal ? returnVal : '0 min';
    }
}

export function getTimeDiff(
    duration,
    showSeconds = true,
    humanize = false,
    isPadded = false,
    showNegative = false,
    padDays = true,
    moreConfig = {}
) {
    const { abbreviations = {} } = moreConfig || {};

    const minAbbr = get(abbreviations, 'minute');
    const dayAbbr = get(abbreviations, 'day');
    const monthAbbr = get(abbreviations, 'month');
    const yearAbbr = get(abbreviations, 'year');

    const d = moment.duration(duration);
    const years = d.years();
    const months = d.months();
    let days = d.days();
    let h = d.hours();
    let m = d.minutes();
    const s = d.seconds();
    const showMinutes = m > 0;
    if (!showMinutes && !showNegative) {
        showSeconds = true;
    }
    if (showMinutes && !showSeconds && s >= 30) {
        m += 1;
    }
    if (m === 60) {
        m = 0;
        h++;
    }
    if (h === 24) {
        h = 0;
        days++;
    }

    if (s == 0) {
        showSeconds = false;
    }

    const yearsSuffix = yearAbbr ? yearAbbr : 'year' + (years > 1 ? 's' : '');
    const daysSuffix = dayAbbr ? dayAbbr : 'day' + (days > 1 ? 's' : '');
    const monthsSuffix = monthAbbr ? monthAbbr : 'month' + (months > 1 ? 's' : '');
    const minSuffix = minAbbr ? minAbbr : 'm';
    if (humanize) {
        return (
            (years > 0 || (showNegative && years !== 0) ? years + yearsSuffix + ' ' : '') +
            (months > 0 || (showNegative && months !== 0) ? months + monthsSuffix + ' ' : '') +
            (days > 0 || (showNegative && days !== 0) ? days + daysSuffix + ' ' : '') +
            (h > 0 || (showNegative && h !== 0) ? h + 'h ' : '') +
            (showMinutes || (showNegative && m !== 0) ? m + minSuffix : '') +
            (showMinutes && showSeconds ? ' ' : '') +
            (showSeconds ? s + 's' : '')
        );
    } else {
        if (isPadded) {
            return (
                (years > 0 ? years + ':' : '') +
                (months > 0 ? months + ':' : '') +
                (days > 0 ? padStart(days, 2, '0') + ':' : padDays ? '00:' : '') +
                (h > 0 ? padStart(h, 2, '0') : '00') +
                (showMinutes ? ':' + padStart(m, 2, '0') : ':00') +
                (showSeconds ? ':' + padStart(s, 2, '0') : '')
            );
        } else {
            return (
                (years > 0 ? years + ':' : '') +
                (months > 0 ? months + ':' : '') +
                (days > 0 ? days + ':' : '') +
                (h > 0 ? h + ':' : '') +
                (showMinutes ? padStart(m, 2, '0') : '') +
                (showMinutes && showSeconds ? ':' : '') +
                (showSeconds ? padStart(s, 2, '0') : '')
            );
        }
    }
}

export const getHHMMSSFromString = (hhmmss) => {
    hhmmss = hhmmss.trim();
    if (hhmmss === '-') {
        return hhmmss;
    }
    return `${toSafeInteger(hhmmss.substring(0, 2))}:${toSafeInteger(hhmmss.substring(2, 4))}:${toSafeInteger(
        hhmmss.substring(4, 6)
    )}`;
};

export const getDiffFromHHMMSS = (earlierTS, laterTS, isNumeric = false) => {
    earlierTS = earlierTS.toString().trim();
    laterTS = laterTS.toString().trim();
    if (earlierTS !== '-' && laterTS !== '-' && earlierTS && laterTS) {
        const hh_dpt = toSafeInteger(laterTS.substring(0, 2));
        const hh_arr = toSafeInteger(earlierTS.substring(0, 2));
        const mm_dpt = toSafeInteger(laterTS.substring(2, 4));
        const ss_dpt = toSafeInteger(laterTS.substring(4, 6));
        const mm_arr = toSafeInteger(earlierTS.substring(2, 4));
        const ss_arr = toSafeInteger(earlierTS.substring(4, 6));
        let dpt = getMomentTime();
        let arr = getMomentTime();
        if (hh_dpt > hh_arr) {
            //using dummy date since we only have time data
            dpt = moment('2019-10-10').set({ 'hour': hh_dpt, 'minute': mm_dpt, 'second': ss_dpt });
            arr = moment('2019-10-10').set({ 'hour': hh_arr, 'minute': mm_arr, 'second': ss_arr });
        } else {
            dpt = moment('2019-10-11').set({ 'hour': hh_dpt, 'minute': mm_dpt, 'second': ss_dpt });
            arr = moment('2019-10-10').set({ 'hour': hh_arr, 'minute': mm_arr, 'second': ss_arr });
        }

        let duration = dpt.diff(arr);
        return isNumeric ? duration : getTimeDiffHours(duration);
    } else return '';
};

export function getEndDate(endDate) {
    return endDate ? endDate : nearestPastMinutes();
}

export function getDayEndDate(endDate) {
    return endDate ? getMomentTime(endDate).endOf('day') : getMomentTime().endOf('day');
}

export function getEndDateTillEndOfDay(endDate) {
    return endDate ? endDate : getMomentTime().endOf('day');
}

export function getEndDateWithCurrent(endDate) {
    return endDate ? endDate : getMomentTime();
}

export function nearestMinutes(someMoment = getMomentTime(), interval = 15) {
    const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestPastMinutes(someMoment = getMomentTime(), interval = 1) {
    const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(someMoment = getMomentTime(), interval = 15) {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
}

export function isDateEqual(moment1, moment2, unit = 'minutes', threshold = 1) {
    return moment1 && moment2 && Math.abs(moment1.diff(moment2, unit)) <= threshold;
}

export function lastWeekRange() {
    return [
        getMomentTime().subtract(1, 'week').startOf('isoweek'),
        getMomentTime().subtract(1, 'week').endOf('isoweek'),
    ];
}

export function lastMonthRange() {
    return [getMomentTime().subtract(1, 'month').startOf('month'), getMomentTime().subtract(1, 'month').endOf('month')];
}

export function convertDecimalHoursToTime(time) {
    const decimalHours = parseFloat(time);
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours} hrs and ${minutes} mins`;
}

export function last7DaysRange() {
    return [getMomentTime().subtract(6, 'days').startOf('day'), getEndDate()];
}

export function last15DaysRange() {
    return [getMomentTime().subtract(14, 'days').startOf('day'), getEndDate()];
}

export function getDurationAsDays(duration) {
    return round(moment.duration(duration).asDays(), 1);
}

export function getDurationAsHours(duration, roundOffTill) {
    return round(moment.duration(duration).asHours(), roundOffTill ? roundOffTill : 1);
}

export function getDurationAsYears(duration) {
    return moment.duration(duration).asYears();
}

export function getTimeDiffInHours(startTime, endTime, type) {
    const duration = moment.duration((endTime ? endTime : moment()).diff(startTime));
    if (type === 'hours') {
        return round(duration.asHours(), 0);
    } else if (type === 'days') {
        return round(duration.asDays(), 0);
    }
}

export const getTimeDiffHHMMSS = (start, end) => {
    if (isEmpty(start) || isEmpty(end)) {
        return '00:00:00';
    }

    const duration = moment.duration(moment(end).diff(moment(start)));
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};

export const DAYS_OPTIONS = [
    { value: 1, label: 'Sun' },
    { value: 2, label: 'Mon' },
    { value: 3, label: 'Tue' },
    { value: 4, label: 'Wed' },
    { value: 5, label: 'Thu' },
    { value: 6, label: 'Fri' },
    { value: 7, label: 'Sat' },
];
export const DATE_OPTIONS = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
];

export const HOUR_OPTIONS = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
];

export const MINUTE_OPTIONS = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 },
    { value: 51, label: 51 },
    { value: 52, label: 52 },
    { value: 53, label: 53 },
    { value: 54, label: 54 },
    { value: 55, label: 55 },
    { value: 56, label: 56 },
    { value: 57, label: 57 },
    { value: 58, label: 58 },
    { value: 59, label: 59 },
];

// https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const getNumberWithOrdinal = (n) => {
    var s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export function renderDateCol(date) {
    return date ? getHumanizeTime(date) : null;
}

export function getYYYYMMDDFormatStringFrom(fromString, currentFormat, requiredFormat = 'YYYY-MM-DD') {
    if (currentFormat === 'DD-MM-YYYY' || currentFormat === 'DD/MM/YYYY') {
        const reg = /([0-9]+)[-/]([0-9]+)[-/]([0-9]+)([\s]*)([0-9:]+)([\s]*)?(PM|AM|am|pm)?/; // 26-02-2022 11:50:10 to 2022-02-26 11:50:10
        const splitDate = fromString.match(reg);
        return `${splitDate[3]}-${splitDate[2]}-${splitDate[1]} ${splitDate[5]}${
            splitDate[7] ? ' ' + splitDate[7] : ''
        }`;
    }
}

/**
 *
 * @param dateString : string   YYYYMMDD (20220422)
 * @returns {string} : YYYY-MM-DDTHH:mm:ss
 */
export function getDateFromYYMMDD(dateString) {
    const splitString = split(dateString, ''); // len 8
    return `${splitString.slice(0, 4).join('')}-${splitString.slice(4, 6).join('')}-${splitString
        .slice(6, 8)
        .join('')}T00:00:00`;
}

/**
 * Parses the date from the string input.
 * @param {Number|Date|string} date Teh value to be parsed.
 * @param {Function|Date} [defaultValue] The default value to use if the date cannot be parsed.
 * @returns {Date|string|number} The parsed date value. If the date is invalid or can't be parsed, then the defaultValue is returned. string:  YYYY-MM-DD HH:mm
 */
export function parseDateFromUserInput(date, defaultValue) {
    function getDefaultValue() {
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    }

    if (!date) return getDefaultValue();

    if (typeof date === 'date' || typeof date === 'object') return date;
    if (typeof date === 'number') return new getMomentTime(date);

    let parsedDate = trim(date);
    let results;

    // YYYY-MM-DD HH:mm
    if ((results = /(\d{4})[-\/\\](\d{1,2})[-\/\\](\d{1,2})([\s]*)([\d:]*)([\s]*)(PM|AM|am|pm)?/.exec(parsedDate))) {
        const dateString = `${results[1]}-${padStart(results[2], 2, '0')}-${padStart(results[3], 2, '0')}`;
        const timeString = `${results[5] ? results[5] : ''} ${results[7] ? results[7] : ''}`;
        return trim(`${dateString} ${timeString}`);
    }
    // DD/MM/YYYY HH:mm
    if ((results = /(\d{1,2})[-\/\\](\d{1,2})[-\/\\](\d{4})([\s]*)([\d:]*)([\s]*)(PM|AM|am|pm)?/.exec(date))) {
        const dateString = `${results[3]}-${padStart(results[2], 2, '0')}-${padStart(results[1], 2, '0')}`;
        const timeString = `${results[5] ? results[5] : ''} ${results[7] ? results[7] : ''}`;
        return trim(`${dateString} ${timeString}`);
    }
    return getMomentTime(date) || getDefaultValue();
}

export function getInputStringFromDateRange(startDate, endDate, singleDatePicker = false, timePicker = true) {
    const format = timePicker ? DATE_FORMAT_TIME_24 : DATE_FORMAT;
    if (singleDatePicker) {
        return startDate ? `${getMomentTime(startDate).format(format)}` : '';
    }
    return startDate && endDate
        ? `${getMomentTime(startDate).format(format)} - ${getMomentTime(endDate).format(format)}`
        : '';
}

export function getDateRangeFromInputString(input, singleDatePicker = false) {
    const splitString = split(input, '-');
    const startDateString = singleDatePicker ? trim(input) : trim(splitString[0]);
    const endDateString = trim(splitString[1]);

    if (!startDateString || (!singleDatePicker && !endDateString)) {
        return {
            error: 'Invalid date',
        };
    }

    const startDate = getMomentTime(parseDateFromUserInput(startDateString), false, '', {
        considerDateStringInUserTimezone: true,
    });
    const endDate = getMomentTime(parseDateFromUserInput(endDateString), false, '', {
        considerDateStringInUserTimezone: true,
    });

    if (!startDate.isValid() || (!singleDatePicker && !endDate.isValid())) {
        return {
            error: 'Invalid date',
        };
    }

    return {
        startDate,
        endDate,
    };
}

export function getCurrentYearRange() {
    return [getMomentTime().startOf('year'), getMomentTime().endOf('year')];
}

export function getLastYearRange() {
    const anyDateInLastYear = getMomentTime().startOf('year').subtract(1, 'day');
    return [getMomentTime(anyDateInLastYear).startOf('year'), getMomentTime(anyDateInLastYear).endOf('year')];
}

export function getLastYearRangeFromASpecificDate(fromDate) {
    const now = getMomentTime(fromDate);
    const anyDateInLastYear = now.startOf('year').subtract(1, 'day');
    return [getMomentTime(anyDateInLastYear).startOf('year'), getMomentTime(anyDateInLastYear).endOf('year')];
}

export function getLastQuarterRange(fromDate) {
    const now = fromDate ? fromDate : getMomentTime();
    const currentQuarter = now.quarter();
    const lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
    const lastQuarterStartDate = getMomentTime().quarter(lastQuarter).startOf('quarter');
    const lastQuarterEndDate = getMomentTime().quarter(lastQuarter).endOf('quarter');
    return [lastQuarterStartDate, lastQuarterEndDate];
}

export function getLastMonthRange(fromDate) {
    const startLastMonth = getMomentTime(fromDate).subtract(1, 'month').startOf('month');
    const endLastMonth = getMomentTime(fromDate).subtract(1, 'month').endOf('month');

    return [startLastMonth, endLastMonth];
}

export function getLastXDaysRange(xDays, fromDate = null) {
    return [
        getMomentTime(fromDate)
            .subtract(xDays - 1, 'day')
            .startOf('day'),
        getEndDate(fromDate),
    ];
}

export const ALL_TIMEZONE = [
    'Africa/Accra',
    'Africa/Gaborone',
    'Africa/Nairobi',
    'Africa/Dar_es_Salaam',
    'Africa/Johannesburg',
    'Africa/Kigali',
    'Africa/Kampala',
    'Africa/Lagos',
    'Africa/Maputo',
    'Africa/Lusaka',
    'Africa/Cairo',
    'Asia/Dubai',
    'Asia/Ho_Chi_Minh',
    'Asia/Riyadh',
    'Asia/Jakarta',
    'Asia/Kolkata',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Dhaka',
    'Asia/Colombo',
    'Africa/Libreville',
    'Asia/Bangkok',
    'Africa/Dakar',
];

export const getTimeDiffAsDayCount = (duration, includeToday = false) => {
    if (includeToday) {
        return Math.ceil(moment.duration(duration).asDays());
    }
    return Math.floor(moment.duration(duration).asDays());
};
