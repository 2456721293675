import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_REPORTING_NODE_API_URL } from '../../../constant';
import { queryString } from '../../../utils/string_utils';
import { set } from 'lodash';
import { isViewAddressBookSummaryAllowedForUser } from '../../../components/dashboard/roles/permission_utils';
import { getPhonePrefixFromCountry } from 'utils/internationalization_utils';

export const CREATE_ADDRESS = 'create_address';
export const FETCH_ADDRESS_BOOK = 'fetch_address_book';
export const FETCH_ADDRESS_BOOK_PAGINATED = 'fetch_address_book_paginated';
export const FETCH_ADDRESS_BOOK_MAP = 'fetch_address_book_map';
export const FETCH_ADDRESS = 'fetch_address';
export const UPDATE_ADDRESS = 'update_address';
export const DELETE_ADDRESS = 'delete_address';
export const ARCHIVE_UNARCHIVE_ADDRESS = 'archive_unarchive_address';
export const SUBMIT_ASK_LOCATION = 'submit_ask_location';
export const ASK_FOR_LOCATION = 'ask_for_location';
export const CREATE_ADDRESS_SHARE_LINK = 'create_address_share_link';
export const SHARE_ADDRESS_LINK = 'share_address_link';
export const FETCH_NEAREST_ADDRESS = 'fetch_nearest_address';
export const FETCH_ASSETS_SUMMARY = 'fetch_assets_summary';
export const DOWNLOAD_ALL_VEHICLE_FUEL_REPORT = 'download_all_vehicle_fuel_report';
export const DONWLOAD_UP_TIME_REPORT = 'download_up_time_report';
export const FETCH_ACTUAL_FUEL_FILL = 'fetch_actual_fuel_fill';
export const DOWNLOAD_ADDRESS_BOOK_UPDATE_REPORT = 'download_address_book_update_report';
export const FETCH_ADDRESS_BOOK_MINI_LIST = 'FETCH_ADDRESS_BOOK_MINI_LIST';
export const FETCH_PIN_CODES = 'FETCH_PIN_CODES';

export function createAddress(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}address_book/`, values, config);
    return {
        type: CREATE_ADDRESS,
        promise: request,
    };
}

export function updateAddress(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}address_book/`, values, config);

    return {
        type: UPDATE_ADDRESS,
        promise: request,
    };
}

export function fetchAssetsSummary(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.get(`${ROOT_API_URL}assets/locks/summary`, config);

    return {
        type: FETCH_ASSETS_SUMMARY,
        promise: request,
    };
}

export function fetchAddressBookPaginated(
    accesstoken,
    groupId,
    useGroupQuery = false,
    category,
    size,
    currentPage,
    enabled,
    tagIds = [],
    customerId,
    searchTerm,
    moreFilters = {}
) {
    const { pinCode, city, isZone, state, riskPoint, code, addressBookType, controllingBranchId } = moreFilters || {};
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    if (!isViewAddressBookSummaryAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ADDRESS_BOOK_PAGINATED,
            promise: Promise.resolve({}),
        };
    }
    //const groupQuery = useGroupQuery ? (groupId && groupId != -1 ? `?groupId=${groupId}` : '') : '';

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            category,
            size,
            page: currentPage,
            enabled,
            customerId,
            name: searchTerm,
            pinCode,
            city,
            isZone,
            state,
            riskPoint,
            code,
            addressBookType,
            controllingBranchId,
        }),
    };
    const tagIdsParams = tagIds.length ? `tagIds=${tagIds}` : '';
    const request = axios.get(`${ROOT_API_URL}address_book/search?${tagIdsParams}`, config);

    return {
        type: FETCH_ADDRESS_BOOK_PAGINATED,
        promise: request,
    };
}

export function fetchAddressBook(accesstoken, groupId, useGroupQuery = false, category, enabled, accountId) {
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    //const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    if (!isViewAddressBookSummaryAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_ADDRESS_BOOK,
            promise: Promise.resolve({}),
        };
    }
    //const groupQuery = useGroupQuery ? (groupId && groupId != -1 ? `?groupId=${groupId}` : '') : '';

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId: useGroupQuery && groupId && groupId != -1 ? groupId : null,
            category,
            enabled,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}address_book/`, config);

    return {
        type: FETCH_ADDRESS_BOOK,
        promise: request,
    };
}

export function fetchAddressBookForMap({ addressBookId, nearByKm, enabled }) {
    const config = {
        params: parseQueryParams({
            addressBookId,
            nearByKm,
            enabled,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}address_book/nearby`, config);

    return {
        type: FETCH_ADDRESS_BOOK_MAP,
        promise: request,
    };
}

export function fetchAddress(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}address_book/${id}`, config);
    return {
        type: FETCH_ADDRESS,
        promise: request,
    };
}

export function deleteAddress(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}address_book/${id}`, config);
    return {
        type: DELETE_ADDRESS,
        promise: request,
    };
}

export function archiveUnarchiveAddress(accesstoken, id, enabled) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
            enabled,
        }),
    };

    const request = axios.patch(`${ROOT_API_URL}address_book/archive-unarchive`, null, config);

    return {
        type: ARCHIVE_UNARCHIVE_ADDRESS,
        promise: request,
    };
}

export function askForLocation(accesstoken, values) {
    const phoneNumberPrefix = getPhonePrefixFromCountry();
    if (values.number && !values.number.startsWith(`${phoneNumberPrefix}-`)) {
        set(values, 'number', `${phoneNumberPrefix}-` + values.number);
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${ROOT_API_URL}share/ask`, values, config);

    return {
        type: ASK_FOR_LOCATION,
        promise: request,
    };
}

export function submitAskLocation(token, latitude, longitude, address) {
    const config = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const data = {
        token,
        latitude,
        longitude,
        address,
    };
    const request = axios.post(`${ROOT_API_URL}share/location`, queryString.stringify(data), config);

    return {
        type: SUBMIT_ASK_LOCATION,
        promise: request,
    };
}

export function createAddressShareLink(accesstoken, addressBookId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const data = {
        addressBookId,
    };
    const request = axios.post(`${ROOT_API_URL}address_book/location/share`, data, config);
    return {
        type: CREATE_ADDRESS_SHARE_LINK,
        promise: request,
    };
}

export function shareAddressLink(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}notify/address-book/location`, data, config);
    return {
        type: SHARE_ADDRESS_LINK,
        promise: request,
    };
}

export function fetchNearestAddress(accesstoken, data) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetch_nearest_address';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.post(`${ROOT_API_URL}address_book/nearest/bulk/`, data, config);

    return {
        type: FETCH_NEAREST_ADDRESS,
        promise: request,
    };
}

export function fetchNodeAllVehicleReport(
    accesstoken,
    reportFormat,
    // filterConfig,
    startDate,
    endDate,
    reportRequestMode,
    emails
) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        // format = {  responseType: 'blob' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            // filterConfig,
            startDate,
            endDate,
            reportRequestMode,
            emails,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getAllVehicleFuelReport`, config);

    return {
        type: DOWNLOAD_ALL_VEHICLE_FUEL_REPORT,
        promise: request,
    };
}

export function fetchActualFuelFill(accesstoken, startDate, endDate) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: startDate,
            to: endDate,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}fuel_entries/search`, config);

    return {
        type: FETCH_ACTUAL_FUEL_FILL,
        promise: request,
    };
}

export function getAddressBookUpdateReport(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}address_book/report`, config);

    return {
        type: DOWNLOAD_ADDRESS_BOOK_UPDATE_REPORT,
        promise: request,
    };
}

export function fetchAddressBookMiniList(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}address_book/autocomplete`, config);

    return {
        type: 'FETCH_ADDRESS_BOOK_MINI_LIST',
        promise: request,
    };
}

export function fetchPinCodes(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}address_book/pincode-details/`, config);

    return {
        type: FETCH_PIN_CODES,
        promise: request,
    };
}
